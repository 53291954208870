import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

import PortablePoolSignupFormSpanish from "../components/PortablePoolModal/PortablePoolSignupFormSpanish"

const SwimLessonsRegistrationEspanol = ({data}) => (
    <Layout>
<SEO title="Verano 2021 Aplicación portátil para lecciones de natación en piscina" />
<Container>
    <Row className="mt-4">
        <Col>
            <h1 style={{ color: '#003366'}}>Verano 2021 Aplicación portátil para lecciones de natación en piscina</h1>
        </Col>
        </Row>
        <Row className="py-4">
            <Col>
            <h2>El período de solicitud ha finalizado.</h2>
            {/* <PortablePoolSignupFormSpanish />  */}
            </Col>
        </Row>
        
        </Container>
    </Layout>
)

export default SwimLessonsRegistrationEspanol;

